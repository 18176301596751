'use strict';

angular.module('enervexSalesappApp').factory('StackRule', function ($resource) {
	return $resource('/api/stack-rules/:id', {
		id: '@_id'
	},
	{
		update: {
			method: 'PUT'
		}
	});
});
